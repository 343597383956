/*!
 * ngToast v2.0.0 (http://tameraydin.github.io/ngToast)
 * Copyright 2016 Tamer Aydin (http://tamerayd.in)
 * Licensed under MIT (http://tameraydin.mit-license.org/)
 */

!(function (a, b, c) {
  'use strict';
  b.module('ngToast.provider', []).provider('ngToast', [
    function () {
      function a(a) {
        for (var d = Math.floor(1e3 * Math.random()); c.indexOf(d) > -1; )
          d = Math.floor(1e3 * Math.random());
        (this.id = d),
          (this.count = 0),
          (this.animation = e.animation),
          (this.className = e.className),
          (this.additionalClasses = e.additionalClasses),
          (this.dismissOnTimeout = e.dismissOnTimeout),
          (this.timeout = e.timeout),
          (this.dismissButton = e.dismissButton),
          (this.dismissButtonHtml = e.dismissButtonHtml),
          (this.dismissOnClick = e.dismissOnClick),
          (this.onDismiss = e.onDismiss),
          (this.compileContent = e.compileContent),
          b.extend(this, a);
      }
      var c = [],
        d = [],
        e = {
          animation: !1,
          className: 'success',
          additionalClasses: null,
          dismissOnTimeout: !0,
          timeout: 4e3,
          dismissButton: !1,
          dismissButtonHtml: '&times;',
          dismissOnClick: !0,
          onDismiss: null,
          compileContent: !1,
          combineDuplications: !1,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          maxNumber: 0,
          newestOnTop: !0,
        };
      (this.configure = function (a) {
        b.extend(e, a);
      }),
        (this.$get = [
          function () {
            var b = function (a, b) {
              return (
                (b = 'object' == typeof b ? b : { content: b }), (b.className = a), this.create(b)
              );
            };
            return {
              settings: e,
              messages: c,
              dismiss: function (a) {
                if (a) {
                  for (var b = c.length - 1; b >= 0; b--)
                    if (c[b].id === a) return c.splice(b, 1), void d.splice(d.indexOf(a), 1);
                } else {
                  for (; c.length > 0; ) c.pop();
                  d = [];
                }
              },
              create: function (b) {
                if (((b = 'object' == typeof b ? b : { content: b }), e.combineDuplications))
                  for (var f = d.length - 1; f >= 0; f--) {
                    var g = c[f],
                      h = b.className || 'success';
                    if (g.content === b.content && g.className === h) return void c[f].count++;
                  }
                e.maxNumber > 0 && d.length >= e.maxNumber && this.dismiss(d[0]);
                var i = new a(b);
                return c[e.newestOnTop ? 'unshift' : 'push'](i), d.push(i.id), i.id;
              },
              success: function (a) {
                return b.call(this, 'success', a);
              },
              info: function (a) {
                return b.call(this, 'info', a);
              },
              warning: function (a) {
                return b.call(this, 'warning', a);
              },
              danger: function (a) {
                return b.call(this, 'danger', a);
              },
            };
          },
        ]);
    },
  ]);
})(window, window.angular),
  (function (a, b) {
    'use strict';
    b.module('ngToast.directives', ['ngToast.provider'])
      .run([
        '$templateCache',
        function (a) {
          a.put(
            'ngToast/toast.html',
            '<div class="ng-toast ng-toast--{{hPos}} ng-toast--{{vPos}} {{animation ? \'ng-toast--animate-\' + animation : \'\'}}"><ul class="ng-toast__list"><toast-message ng-repeat="message in messages" message="message" count="message.count"><span ng-bind-html="message.content"></span></toast-message></ul></div>',
          ),
            a.put(
              'ngToast/toastMessage.html',
              '<li class="ng-toast__message {{message.additionalClasses}}"ng-mouseenter="onMouseEnter()"ng-mouseleave="onMouseLeave()"><div class="alert alert-{{message.className}}" ng-class="{\'alert-dismissible\': message.dismissButton}"><button type="button" class="close" ng-if="message.dismissButton" ng-bind-html="message.dismissButtonHtml" ng-click="!message.dismissOnClick && dismiss()"></button><span ng-if="count" class="ng-toast__message__count">{{count + 1}}</span><span ng-if="!message.compileContent" ng-transclude></span></div></li>',
            );
        },
      ])
      .directive('toast', [
        'ngToast',
        '$templateCache',
        '$log',
        function (a, b, c) {
          return {
            replace: !0,
            restrict: 'EA',
            templateUrl: 'ngToast/toast.html',
            compile: function (d, e) {
              if (e.template) {
                var f = b.get(e.template);
                f
                  ? d.replaceWith(f)
                  : c.warn(
                      'ngToast: Provided template could not be loaded. Please be sure that it is populated before the <toast> element is represented.',
                    );
              }
              return function (b) {
                (b.hPos = a.settings.horizontalPosition),
                  (b.vPos = a.settings.verticalPosition),
                  (b.animation = a.settings.animation),
                  (b.messages = a.messages);
              };
            },
          };
        },
      ])
      .directive('toastMessage', [
        '$timeout',
        '$compile',
        'ngToast',
        function (a, b, c) {
          return {
            replace: !0,
            transclude: !0,
            restrict: 'EA',
            scope: { message: '=', count: '=' },
            controller: [
              '$scope',
              'ngToast',
              function (a, b) {
                a.dismiss = function () {
                  b.dismiss(a.message.id);
                };
              },
            ],
            templateUrl: 'ngToast/toastMessage.html',
            link: function (d, e, f, g, h) {
              e.attr('data-message-id', d.message.id);
              var i,
                j = d.message.compileContent;
              if (
                ((d.cancelTimeout = function () {
                  a.cancel(i);
                }),
                (d.startTimeout = function () {
                  d.message.dismissOnTimeout &&
                    (i = a(function () {
                      c.dismiss(d.message.id);
                    }, d.message.timeout));
                }),
                (d.onMouseEnter = function () {
                  d.cancelTimeout();
                }),
                (d.onMouseLeave = function () {
                  d.startTimeout();
                }),
                j)
              ) {
                var k;
                h(d, function (a) {
                  (k = a), e.children().append(k);
                }),
                  a(function () {
                    b(k.contents())('boolean' == typeof j ? d.$parent : j, function (a) {
                      k.replaceWith(a);
                    });
                  }, 0);
              }
              d.startTimeout(),
                d.message.dismissOnClick &&
                  e.bind('click', function () {
                    c.dismiss(d.message.id), d.$apply();
                  }),
                d.message.onDismiss && d.$on('$destroy', d.message.onDismiss.bind(d.message));
            },
          };
        },
      ]);
  })(window, window.angular),
  (function (a, b) {
    'use strict';
    b.module('ngToast', ['ngSanitize', 'ngToast.directives', 'ngToast.provider']);
  })(window, window.angular);
