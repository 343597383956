/*!
 * froala_editor v3.1.1 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2020 Froala Labs
 */

!(function (e, t) {
  'object' == typeof exports && 'undefined' != typeof module
    ? t(require('froala-editor'))
    : 'function' == typeof define && define.amd
    ? define(['froala-editor'], t)
    : t(e.FroalaEditor);
})(this, function (E) {
  'use strict';
  (E = E && E.hasOwnProperty('default') ? E['default'] : E),
    Object.assign(E.POPUP_TEMPLATES, {
      'embedly.insert': '[_BUTTONS_][_URL_LAYER_]',
      'embedly.edit': '[_BUTTONS_]',
    }),
    Object.assign(E.DEFAULTS, {
      embedlyKey: null,
      embedlyInsertButtons: ['embedlyBack', '|'],
      embedlyEditButtons: ['embedlyRemove'],
      embedlyScriptPath: 'https://cdn.embedly.com/widgets/platform.js',
    }),
    (E.PLUGINS.embedly = function (o) {
      var i,
        d,
        l = o.$;
      function t(e) {
        o.events.on('html.processGet', s),
          e && o.html._setHtml(o.$el, o.html.get()),
          o.events.$on(o.$el, 'click touchend', 'div.fr-embedly', a),
          o.events.on('mousedown window.mousedown', b),
          o.events.on('window.touchmove', c),
          o.events.on('mouseup window.mouseup', u),
          o.events.on('commands.mousedown', function (e) {
            0 < e.parents('.fr-toolbar').length && u();
          }),
          o.events.on(
            'blur video.hideResizer commands.undo commands.redo element.dropped',
            function () {
              u(!0);
            },
          ),
          o.events.on('element.beforeDrop', function (e) {
            if (e.hasClass('fr-embedly')) return e.html(e.attr('data-original-embed')), e;
          }),
          o.events.on(
            'keydown',
            function (e) {
              var t = e.which;
              return !i || (t != E.KEYCODE.BACKSPACE && t != E.KEYCODE.DELETE)
                ? i && t == E.KEYCODE.ESC
                  ? (u(!0), e.preventDefault(), !1)
                  : i && t != E.KEYCODE.F10 && !o.keys.isBrowserAction(e)
                  ? (e.preventDefault(), !1)
                  : void 0
                : (e.preventDefault(), m(), !1);
            },
            !0,
          ),
          o.events.on(
            'toolbar.esc',
            function () {
              if (i) return o.events.disableBlur(), o.events.focus(), !1;
            },
            !0,
          ),
          o.events.on(
            'toolbar.focusEditor',
            function () {
              if (i) return !1;
            },
            !0,
          ),
          o.events.on('snapshot.after', function (e) {
            var t = o.doc.createElement('div');
            (t.innerHTML = e.html), s(t), (e.html = t.innerHTML);
          }),
          o.win.embedly &&
            (o.win.embedly('on', 'card.rendered', function (e) {
              n(e);
            }),
            o.win.embedly('on', 'card.resize', function (e) {
              n(e);
            })),
          f(!0);
      }
      function n(e) {
        var t = l(e);
        t
          .parents('.fr-embedly')
          .attr('contenteditable', !1)
          .attr('draggable', !0)
          .addClass('fr-draggable')
          .css('height', t.height())
          .addClass('fr-draggable'),
          o.opts.iframe && o.size.syncIframe();
      }
      function a(e) {
        (i = l(this)),
          (function s() {
            d ||
              (function n() {
                o.shared.$embedly_resizer
                  ? ((d = o.shared.$embedly_resizer),
                    o.shared.$embedly_overlay,
                    o.events.on(
                      'destroy',
                      function () {
                        l('body').first().append(d);
                      },
                      !0,
                    ))
                  : ((o.shared.$embedly_resizer = l(document.createElement('div')).attr(
                      'class',
                      'fr-embedly-resizer',
                    )),
                    (d = o.shared.$embedly_resizer),
                    o.events.$on(
                      d,
                      'mousedown',
                      function (e) {
                        e.stopPropagation();
                      },
                      !0,
                    ));
                o.events.on(
                  'shared.destroy',
                  function () {
                    d.html('').removeData().remove(), (d = null);
                  },
                  !0,
                );
              })();
            (o.$wp || o.$sc).append(d), d.data('instance', o);
            var e = 0,
              t = 0;
            o.opts.iframe &&
              ((t = o.helpers.getPX(o.$wp.find('.fr-iframe').css('padding-top'))),
              (e = o.helpers.getPX(o.$wp.find('.fr-iframe').css('padding-left'))));
            d.css(
              'top',
              (o.opts.iframe
                ? i.offset().top + t - 1 + o.$iframe.position().top
                : i.offset().top - o.$wp.offset().top - 1) + o.$wp.scrollTop(),
            )
              .css(
                'left',
                (o.opts.iframe
                  ? i.offset().left + e - 1
                  : i.offset().left - o.$wp.offset().left - 1) + o.$wp.scrollLeft(),
              )
              .css('width', i.outerWidth())
              .css('height', i.height())
              .addClass('fr-active');
          })(),
          (function r() {
            var e = o.popups.get('embedly.edit');
            e ||
              (e = (function s() {
                var e = '';
                if (0 < o.opts.embedlyEditButtons.length) {
                  var t = {
                      buttons: (e += '<div class="fr-buttons">\n      '.concat(
                        o.button.buildList(o.opts.embedlyEditButtons),
                        '\n      </div>',
                      )),
                    },
                    n = o.popups.create('embedly.edit', t);
                  return (
                    o.events.$on(o.$wp, 'scroll.emebdly-edit', function () {
                      i &&
                        o.popups.isVisible('embedly.edit') &&
                        (o.events.disableBlur(),
                        (function t(e) {
                          a.call(e.get(0));
                        })(i));
                    }),
                    n
                  );
                }
                return !1;
              })());
            if (e) {
              o.popups.setContainer('embedly.edit', o.$sc), o.popups.refresh('embedly.edit');
              var t = i.offset().left + i.outerWidth() / 2,
                n = i.offset().top + i.outerHeight();
              o.popups.show('embedly.edit', t, n, i.outerHeight());
            }
          })();
      }
      function s(e) {
        if (e && o.node.hasClass(e, 'fr-embedly'))
          (e.innerHTML = e.getAttribute('data-original-embed')),
            e.removeAttribute('draggable'),
            e.removeAttribute('contenteditable'),
            e.setAttribute('class', (e.getAttribute('class') || '').replace('fr-draggable', ''));
        else if (e && e.nodeType == Node.ELEMENT_NODE)
          for (var t = e.querySelectorAll('.fr-embedly'), n = 0; n < t.length; n++) s(t[n]);
      }
      function f(e) {
        if (e) return o.popups.onRefresh('embedly.insert', r), !0;
        var t = '';
        0 < o.opts.embedlyInsertButtons.length &&
          ((t += '<div class="fr-buttons fr-tabs">'),
          (t += o.button.buildList(o.opts.embedlyInsertButtons)),
          (t += '</div>'));
        var n = {
          buttons: t,
          url_layer:
            '<div class="fr-embedly-layer fr-active fr-layer" id="fr-embedly-layer-' +
            o.id +
            '"><div class="fr-input-line"><input id="fr-embedly-layer-text-' +
            o.id +
            '" type="text" placeholder="' +
            o.language.translate('Paste in a URL to embed') +
            '" tabIndex="1" aria-required="true"></div><div class="fr-action-buttons"><button type="button" class="fr-command fr-submit" data-cmd="embedlyInsert" tabIndex="2" role="button">' +
            o.language.translate('Insert') +
            '</button></div></div>',
        };
        return o.popups.create('embedly.insert', n);
      }
      function r() {
        o.popups.get('embedly.insert').find('.fr-embedly-layer input').val('').trigger('change');
      }
      function e(e) {
        if (e.length) {
          var t =
            "<a href='" +
            e +
            "' data-card-branding='0' class='embedly-card'" +
            (o.opts.embedlyKey ? " data-card-key='" + o.opts.embedlyKey + "'" : '') +
            '></a>';
          o.html.insert(
            '<div class="fr-embedly fr-draggable" draggable="true" contenteditable="false" data-original-embed="' +
              t +
              '">' +
              t +
              '</div>',
          ),
            o.popups.hideAll();
        }
      }
      function m() {
        if (i && !1 !== o.events.trigger('embedly.beforeRemove', [i])) {
          var e = i;
          o.popups.hideAll(),
            u(!0),
            o.selection.setBefore(e.get(0)) || o.selection.setAfter(e.get(0)),
            e.remove(),
            o.selection.restore(),
            o.html.fillEmptyBlocks(),
            o.undo.saveStep(),
            o.events.trigger('video.removed', [e]);
        }
      }
      function u(e) {
        i &&
          ((function t() {
            return o.shared.embedly_exit_flag;
          })() ||
            !0 === e) &&
          (d.removeClass('fr-active'),
          o.toolbar.enable(),
          i.removeClass('fr-active'),
          (i = null),
          c());
      }
      function b() {
        o.shared.embedly_exit_flag = !0;
      }
      function c() {
        o.shared.embedly_exit_flag = !1;
      }
      return (
        (o.shared.embedly_exit_flag = !1),
        {
          _init: function p() {
            if (!o.$wp) return !1;
            if ('undefined' != typeof embedly) t(!0);
            else if (o.shared.embedlyLoaded) o.shared.embedlyCallbacks.push(t);
            else {
              (o.shared.embedlyLoaded = !0),
                (o.shared.embedlyCallbacks = []),
                o.shared.embedlyCallbacks.push(t);
              var e = o.doc.createElement('script');
              (e.type = 'text/javascript'),
                (e.src = o.opts.embedlyScriptPath),
                (e.innerText = ''),
                (e.onload = function () {
                  if (o.shared.embedlyCallbacks)
                    for (var e = 0; e < o.shared.embedlyCallbacks.length; e++)
                      o.shared.embedlyCallbacks[e]();
                }),
                o.doc.getElementsByTagName('head')[0].appendChild(e);
            }
          },
          showInsertPopup: function y() {
            var e = o.$tb.find('.fr-command[data-cmd="embedly"]'),
              t = o.popups.get('embedly.insert');
            if ((t || (t = f()), !t.hasClass('fr-active')))
              if (
                (o.popups.refresh('embedly.insert'),
                o.popups.setContainer('embedly.insert', o.$tb),
                e.isVisible())
              ) {
                var n = o.button.getPosition(e),
                  s = n.left,
                  r = n.top;
                o.popups.show('embedly.insert', s, r, e.outerHeight());
              } else o.position.forSelection(t), o.popups.show('embedly.insert');
          },
          insert: function h() {
            e(o.popups.get('embedly.insert').find('.fr-embedly-layer input').val());
          },
          remove: m,
          get: function v() {
            return i;
          },
          add: e,
          back: function g() {
            i
              ? (o.events.disableBlur(), i.trigger('click'))
              : (o.events.disableBlur(),
                o.selection.restore(),
                o.events.enableBlur(),
                o.popups.hide('embedly.insert'),
                o.toolbar.showInline());
          },
        }
      );
    }),
    E.DefineIcon('embedly', { NAME: 'share-alt', SVG_KEY: 'insertEmbed' }),
    E.RegisterCommand('embedly', {
      undo: !0,
      focus: !0,
      title: 'Embed URL',
      popup: !0,
      callback: function () {
        this.popups.isVisible('embedly.insert')
          ? (this.$el.find('.fr-marker').length &&
              (this.events.disableBlur(), this.selection.restore()),
            this.popups.hide('embedly.insert'))
          : this.embedly.showInsertPopup();
      },
      plugin: 'embedly',
    }),
    E.RegisterCommand('embedlyInsert', {
      undo: !0,
      focus: !0,
      callback: function () {
        this.embedly.insert();
      },
    }),
    E.DefineIcon('embedlyRemove', { NAME: 'trash', SVG_KEY: 'remove' }),
    E.RegisterCommand('embedlyRemove', {
      title: 'Remove',
      undo: !1,
      callback: function () {
        this.embedly.remove();
      },
    }),
    E.DefineIcon('embedlyBack', { NAME: 'arrow-left', SVG_KEY: 'back' }),
    E.RegisterCommand('embedlyBack', {
      title: 'Back',
      undo: !1,
      focus: !1,
      back: !0,
      callback: function () {
        this.embedly.back();
      },
      refresh: function (e) {
        this.embedly.get() || this.opts.toolbarInline
          ? (e.removeClass('fr-hidden'), e.next('.fr-separator').removeClass('fr-hidden'))
          : (e.addClass('fr-hidden'), e.next('.fr-separator').addClass('fr-hidden'));
      },
    });
});
