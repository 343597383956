'use strict';
angular
  .module('colorpicker.module', [])
  .factory('Helper', function () {
    return {
      closestSlider: function (e) {
        var o = e.matches || e.webkitMatchesSelector || e.mozMatchesSelector || e.msMatchesSelector;
        return o.bind(e)('I') ? e.parentNode : e;
      },
      getOffset: function (e, o) {
        for (var t = 0, n = 0, r = 0, i = 0; e && !isNaN(e.offsetLeft) && !isNaN(e.offsetTop); )
          (t += e.offsetLeft),
            (n += e.offsetTop),
            o || 'BODY' !== e.tagName
              ? ((r += e.scrollLeft), (i += e.scrollTop))
              : ((r += document.documentElement.scrollLeft || e.scrollLeft),
                (i += document.documentElement.scrollTop || e.scrollTop)),
            (e = e.offsetParent);
        return { top: n, left: t, scrollX: r, scrollY: i };
      },
      stringParsers: [
        {
          re: /rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*(?:,\s*(\d+(?:\.\d+)?)\s*)?\)/,
          parse: function (e) {
            return [e[1], e[2], e[3], e[4]];
          },
        },
        {
          re: /rgba?\(\s*(\d+(?:\.\d+)?)\%\s*,\s*(\d+(?:\.\d+)?)\%\s*,\s*(\d+(?:\.\d+)?)\%\s*(?:,\s*(\d+(?:\.\d+)?)\s*)?\)/,
          parse: function (e) {
            return [2.55 * e[1], 2.55 * e[2], 2.55 * e[3], e[4]];
          },
        },
        {
          re: /#([a-fA-F0-9]{2})([a-fA-F0-9]{2})([a-fA-F0-9]{2})/,
          parse: function (e) {
            return [parseInt(e[1], 16), parseInt(e[2], 16), parseInt(e[3], 16)];
          },
        },
        {
          re: /#([a-fA-F0-9])([a-fA-F0-9])([a-fA-F0-9])/,
          parse: function (e) {
            return [
              parseInt(e[1] + e[1], 16),
              parseInt(e[2] + e[2], 16),
              parseInt(e[3] + e[3], 16),
            ];
          },
        },
      ],
    };
  })
  .factory('Color', [
    'Helper',
    function (e) {
      return {
        value: { h: 1, s: 1, b: 1, a: 1 },
        rgb: function () {
          var e = this.toRGB();
          return 'rgb(' + e.r + ',' + e.g + ',' + e.b + ')';
        },
        rgba: function () {
          var e = this.toRGB();
          return 'rgba(' + e.r + ',' + e.g + ',' + e.b + ',' + e.a + ')';
        },
        hex: function () {
          return this.toHex();
        },
        RGBtoHSB: function (e, o, t, n) {
          (e /= 255), (o /= 255), (t /= 255);
          var r, i, a, l;
          return (
            (a = Math.max(e, o, t)),
            (l = a - Math.min(e, o, t)),
            (r =
              0 === l ? null : a === e ? (o - t) / l : a === o ? (t - e) / l + 2 : (e - o) / l + 4),
            (r = (((r + 360) % 6) * 60) / 360),
            (i = 0 === l ? 0 : l / a),
            { h: r || 1, s: i, b: a, a: n || 1 }
          );
        },
        setColor: function (o) {
          o = o.toLowerCase();
          for (var t in e.stringParsers)
            if (e.stringParsers.hasOwnProperty(t)) {
              var n = e.stringParsers[t],
                r = n.re.exec(o),
                i = r && n.parse(r);
              if (i) return (this.value = this.RGBtoHSB.apply(null, i)), !1;
            }
        },
        setHue: function (e) {
          this.value.h = 1 - e;
        },
        setSaturation: function (e) {
          this.value.s = e;
        },
        setLightness: function (e) {
          this.value.b = 1 - e;
        },
        setAlpha: function (e) {
          this.value.a = parseInt(100 * (1 - e), 10) / 100;
        },
        toRGB: function (e, o, t, n) {
          e || ((e = this.value.h), (o = this.value.s), (t = this.value.b)), (e *= 360);
          var r, i, a, l, s;
          return (
            (e = (e % 360) / 60),
            (s = t * o),
            (l = s * (1 - Math.abs((e % 2) - 1))),
            (r = i = a = t - s),
            (e = ~~e),
            (r += [s, l, 0, 0, l, s][e]),
            (i += [l, s, s, l, 0, 0][e]),
            (a += [0, 0, l, s, s, l][e]),
            {
              r: Math.round(255 * r),
              g: Math.round(255 * i),
              b: Math.round(255 * a),
              a: n || this.value.a,
            }
          );
        },
        toHex: function (e, o, t, n) {
          var r = this.toRGB(e, o, t, n);
          return (
            '#' +
            ((1 << 24) | (parseInt(r.r, 10) << 16) | (parseInt(r.g, 10) << 8) | parseInt(r.b, 10))
              .toString(16)
              .substr(1)
          );
        },
      };
    },
  ])
  .factory('Slider', [
    'Helper',
    function (e) {
      var o = { maxLeft: 0, maxTop: 0, callLeft: null, callTop: null, knob: { top: 0, left: 0 } },
        t = {};
      return {
        getSlider: function () {
          return o;
        },
        getLeftPosition: function (e) {
          return Math.max(0, Math.min(o.maxLeft, o.left + ((e.pageX || t.left) - t.left)));
        },
        getTopPosition: function (e) {
          return Math.max(0, Math.min(o.maxTop, o.top + ((e.pageY || t.top) - t.top)));
        },
        setSlider: function (n, r) {
          var i = e.closestSlider(n.target),
            a = e.getOffset(i, r);
          (o.knob = i.children[0].style),
            (o.left = n.pageX - a.left - window.pageXOffset + a.scrollX),
            (o.top = n.pageY - a.top - window.pageYOffset + a.scrollY),
            (t = { left: n.pageX, top: n.pageY });
        },
        setSaturation: function (e, t) {
          (o = { maxLeft: 100, maxTop: 100, callLeft: 'setSaturation', callTop: 'setLightness' }),
            this.setSlider(e, t);
        },
        setHue: function (e, t) {
          (o = { maxLeft: 0, maxTop: 100, callLeft: !1, callTop: 'setHue' }), this.setSlider(e, t);
        },
        setAlpha: function (e, t) {
          (o = { maxLeft: 0, maxTop: 100, callLeft: !1, callTop: 'setAlpha' }),
            this.setSlider(e, t);
        },
        setKnob: function (e, t) {
          (o.knob.top = e + 'px'), (o.knob.left = t + 'px');
        },
      };
    },
  ])
  .directive('colorpicker', [
    '$document',
    '$compile',
    'Color',
    'Slider',
    'Helper',
    function (e, o, t, n, r) {
      return {
        require: '?ngModel',
        restrict: 'A',
        link: function (i, a, l, s) {
          var c,
            u = l.colorpicker ? l.colorpicker : 'hex',
            p = angular.isDefined(l.colorpickerPosition) ? l.colorpickerPosition : 'bottom',
            f = angular.isDefined(l.colorpickerInline) ? l.colorpickerInline : !1,
            d = angular.isDefined(l.colorpickerFixedPosition) ? l.colorpickerFixedPosition : !1,
            v = angular.isDefined(l.colorpickerParent)
              ? a.parent()
              : angular.element(document.body),
            h = angular.isDefined(l.colorpickerWithInput) ? l.colorpickerWithInput : !1,
            g = h ? '<input type="text" name="colorpicker-input">' : '',
            m = f ? '' : '<button type="button" class="close close-colorpicker">&times;</button>',
            k =
              '<div class="colorpicker dropdown"><div class="dropdown-menu"><colorpicker-saturation><i></i></colorpicker-saturation><colorpicker-hue><i></i></colorpicker-hue><colorpicker-alpha><i></i></colorpicker-alpha><colorpicker-preview></colorpicker-preview>' +
              g +
              m +
              '</div></div>',
            b = angular.element(k),
            x = t,
            w = b.find('colorpicker-hue'),
            L = b.find('colorpicker-saturation'),
            S = b.find('colorpicker-preview'),
            P = b.find('i');
          if ((o(b)(i), h)) {
            var T = b.find('input');
            T.on('mousedown', function (e) {
              e.stopPropagation();
            }).on('keyup', function (e) {
              var o = this.value;
              a.val(o), s && i.$apply(s.$setViewValue(o)), e.stopPropagation(), e.preventDefault();
            }),
              a.on('keyup', function () {
                T.val(a.val());
              });
          }
          var C = function () {
            e.on('mousemove', M), e.on('mouseup', y);
          };
          'rgba' === u &&
            (b.addClass('alpha'),
            (c = b.find('colorpicker-alpha')),
            c
              .on('click', function (e) {
                n.setAlpha(e, d), M(e);
              })
              .on('mousedown', function (e) {
                n.setAlpha(e, d), C();
              })),
            w
              .on('click', function (e) {
                n.setHue(e, d), M(e);
              })
              .on('mousedown', function (e) {
                n.setHue(e, d), C();
              }),
            L.on('click', function (e) {
              n.setSaturation(e, d), M(e);
            }).on('mousedown', function (e) {
              n.setSaturation(e, d), C();
            }),
            d && b.addClass('colorpicker-fixed-position'),
            b.addClass('colorpicker-position-' + p),
            'true' === f && b.addClass('colorpicker-inline'),
            v.append(b),
            s &&
              ((s.$render = function () {
                a.val(s.$viewValue);
              }),
              i.$watch(l.ngModel, function (e) {
                I(), h && T.val(e);
              })),
            a.on('$destroy', function () {
              b.remove();
            });
          var H = function () {
              try {
                S.css('backgroundColor', x[u]());
              } catch (e) {
                S.css('backgroundColor', x.toHex());
              }
              L.css('backgroundColor', x.toHex(x.value.h, 1, 1, 1)),
                'rgba' === u && (c.css.backgroundColor = x.toHex());
            },
            M = function (e) {
              var o = n.getLeftPosition(e),
                t = n.getTopPosition(e),
                r = n.getSlider();
              n.setKnob(t, o),
                r.callLeft && x[r.callLeft].call(x, o / 100),
                r.callTop && x[r.callTop].call(x, t / 100),
                H();
              var l = x[u]();
              return a.val(l), s && i.$apply(s.$setViewValue(l)), h && T.val(l), !1;
            },
            y = function () {
              e.off('mousemove', M), e.off('mouseup', y);
            },
            I = function () {
              x.setColor(a.val()),
                P.eq(0).css({ left: 100 * x.value.s + 'px', top: 100 - 100 * x.value.b + 'px' }),
                P.eq(1).css('top', 100 * (1 - x.value.h) + 'px'),
                P.eq(2).css('top', 100 * (1 - x.value.a) + 'px'),
                H();
            },
            A = function () {
              var e,
                o = r.getOffset(a[0]);
              return (
                angular.isDefined(l.colorpickerParent) && ((o.left = 0), (o.top = 0)),
                'top' === p
                  ? (e = { top: o.top - 147, left: o.left })
                  : 'right' === p
                  ? (e = { top: o.top, left: o.left + 126 })
                  : 'bottom' === p
                  ? (e = { top: o.top + a[0].offsetHeight + 2, left: o.left })
                  : 'left' === p && (e = { top: o.top, left: o.left - 150 }),
                { top: e.top + 'px', left: e.left + 'px' }
              );
            },
            $ = function () {
              D();
            };
          f === !1
            ? a.on('click', function () {
                I(), b.addClass('colorpicker-visible').css(A()), e.on('mousedown', $);
              })
            : (I(), b.addClass('colorpicker-visible').css(A())),
            b.on('mousedown', function (e) {
              e.stopPropagation(), e.preventDefault();
            });
          var B = function (e) {
              s && i.$emit(e, { name: l.ngModel, value: s.$modelValue });
            },
            D = function () {
              b.hasClass('colorpicker-visible') &&
                (b.removeClass('colorpicker-visible'),
                B('colorpicker-closed'),
                e.off('mousedown', $));
            };
          b.find('button').on('click', function () {
            D();
          });
        },
      };
    },
  ]);
