/*! angularjs-slider - v6.4.1 -  (c) Rafal Zajac <rzajac@gmail.com>, Valentin Hervieu <valentin@hervieu.me>, Jussi Saarivirta <jusasi@gmail.com>, Angelin Sirbu <angelin.sirbu@gmail.com> -  https://github.com/angular-slider/angularjs-slider -  2017-11-17 */
!(function (a, b) {
  'use strict';
  if ('function' == typeof define && define.amd) define(['angular'], b);
  else if ('object' == typeof module && module.exports) {
    var c = angular || require('angular');
    (c && c.module) || 'undefined' == typeof angular || (c = angular), (module.exports = b(c));
  } else b(a.angular);
})(this, function (a) {
  'use strict';
  var b = a
    .module('rzModule', [])
    .factory('RzSliderOptions', function () {
      var b = {
          floor: 0,
          ceil: null,
          step: 1,
          precision: 0,
          minRange: null,
          maxRange: null,
          pushRange: !1,
          minLimit: null,
          maxLimit: null,
          id: null,
          translate: null,
          getLegend: null,
          stepsArray: null,
          bindIndexForStepsArray: !1,
          draggableRange: !1,
          draggableRangeOnly: !1,
          showSelectionBar: !1,
          showSelectionBarEnd: !1,
          showSelectionBarFromValue: null,
          showOuterSelectionBars: !1,
          hidePointerLabels: !1,
          hideLimitLabels: !1,
          autoHideLimitLabels: !0,
          readOnly: !1,
          disabled: !1,
          interval: 350,
          showTicks: !1,
          showTicksValues: !1,
          ticksArray: null,
          ticksTooltip: null,
          ticksValuesTooltip: null,
          vertical: !1,
          getSelectionBarColor: null,
          getTickColor: null,
          getPointerColor: null,
          keyboardSupport: !0,
          scale: 1,
          enforceStep: !0,
          enforceRange: !1,
          noSwitching: !1,
          onlyBindHandles: !1,
          onStart: null,
          onChange: null,
          onEnd: null,
          rightToLeft: !1,
          reversedControls: !1,
          boundPointerLabels: !0,
          mergeRangeLabelsIfSame: !1,
          customTemplateScope: null,
          logScale: !1,
          customValueToPosition: null,
          customPositionToValue: null,
          selectionBarGradient: null,
          ariaLabel: null,
          ariaLabelledBy: null,
          ariaLabelHigh: null,
          ariaLabelledByHigh: null,
        },
        c = {},
        d = {};
      return (
        (d.options = function (b) {
          a.extend(c, b);
        }),
        (d.getOptions = function (d) {
          return a.extend({}, b, c, d);
        }),
        d
      );
    })
    .factory('rzThrottle', [
      '$timeout',
      function (a) {
        return function (b, c, d) {
          var e,
            f,
            g,
            h =
              Date.now ||
              function () {
                return new Date().getTime();
              },
            i = null,
            j = 0;
          d = d || {};
          var k = function () {
            (j = h()), (i = null), (g = b.apply(e, f)), (e = f = null);
          };
          return function () {
            var l = h(),
              m = c - (l - j);
            return (
              (e = this),
              (f = arguments),
              0 >= m
                ? (a.cancel(i), (i = null), (j = l), (g = b.apply(e, f)), (e = f = null))
                : i || d.trailing === !1 || (i = a(k, m)),
              g
            );
          };
        };
      },
    ])
    .factory('RzSlider', [
      '$timeout',
      '$document',
      '$window',
      '$compile',
      'RzSliderOptions',
      'rzThrottle',
      function (b, c, d, e, f, g) {
        var h = function (a, b) {
          (this.scope = a),
            (this.lowValue = 0),
            (this.highValue = 0),
            (this.sliderElem = b),
            (this.range =
              void 0 !== this.scope.rzSliderModel && void 0 !== this.scope.rzSliderHigh),
            (this.dragging = {
              active: !1,
              value: 0,
              difference: 0,
              position: 0,
              lowLimit: 0,
              highLimit: 0,
            }),
            (this.positionProperty = 'left'),
            (this.dimensionProperty = 'width'),
            (this.handleHalfDim = 0),
            (this.maxPos = 0),
            (this.precision = 0),
            (this.step = 1),
            (this.tracking = ''),
            (this.minValue = 0),
            (this.maxValue = 0),
            (this.valueRange = 0),
            (this.intermediateTicks = !1),
            (this.initHasRun = !1),
            (this.firstKeyDown = !1),
            (this.internalChange = !1),
            (this.cmbLabelShown = !1),
            (this.currentFocusElement = null),
            (this.fullBar = null),
            (this.selBar = null),
            (this.minH = null),
            (this.maxH = null),
            (this.flrLab = null),
            (this.ceilLab = null),
            (this.minLab = null),
            (this.maxLab = null),
            (this.cmbLab = null),
            (this.ticks = null),
            this.init();
        };
        return (
          (h.prototype = {
            init: function () {
              var b,
                c,
                e = this,
                f = function () {
                  e.calcViewDimensions();
                };
              this.applyOptions(),
                this.syncLowValue(),
                this.range && this.syncHighValue(),
                this.initElemHandles(),
                this.manageElementsStyle(),
                this.setDisabledState(),
                this.calcViewDimensions(),
                this.setMinAndMax(),
                this.addAccessibility(),
                this.updateCeilLab(),
                this.updateFloorLab(),
                this.initHandles(),
                this.manageEventsBindings(),
                this.scope.$on('reCalcViewDimensions', f),
                a.element(d).on('resize', f),
                (this.initHasRun = !0),
                (b = g(function () {
                  e.onLowHandleChange();
                }, e.options.interval)),
                (c = g(function () {
                  e.onHighHandleChange();
                }, e.options.interval)),
                this.scope.$on('rzSliderForceRender', function () {
                  e.resetLabelsValue(), b(), e.range && c(), e.resetSlider();
                }),
                this.scope.$watchCollection('rzSliderOptions()', function (a, b) {
                  a !== b &&
                    (e.applyOptions(),
                    e.syncLowValue(),
                    e.range && e.syncHighValue(),
                    e.resetSlider());
                }),
                this.scope.$watch('rzSliderModel', function (a, c) {
                  e.internalChange || (a !== c && b());
                }),
                this.scope.$watch('rzSliderHigh', function (a, b) {
                  e.internalChange ||
                    (a !== b &&
                      (null != a && c(),
                      ((e.range && null == a) || (!e.range && null != a)) &&
                        (e.applyOptions(), e.resetSlider())));
                }),
                this.scope.$on('$destroy', function () {
                  e.unbindEvents(), a.element(d).off('resize', f), (e.currentFocusElement = null);
                });
            },
            findStepIndex: function (b) {
              for (var c = 0, d = 0; d < this.options.stepsArray.length; d++) {
                var e = this.options.stepsArray[d];
                if (e === b) {
                  c = d;
                  break;
                }
                if (a.isDate(e)) {
                  if (e.getTime() === b.getTime()) {
                    c = d;
                    break;
                  }
                } else if (
                  a.isObject(e) &&
                  ((a.isDate(e.value) && e.value.getTime() === b.getTime()) || e.value === b)
                ) {
                  c = d;
                  break;
                }
              }
              return c;
            },
            syncLowValue: function () {
              this.options.stepsArray
                ? this.options.bindIndexForStepsArray
                  ? (this.lowValue = this.scope.rzSliderModel)
                  : (this.lowValue = this.findStepIndex(this.scope.rzSliderModel))
                : (this.lowValue = this.scope.rzSliderModel);
            },
            syncHighValue: function () {
              this.options.stepsArray
                ? this.options.bindIndexForStepsArray
                  ? (this.highValue = this.scope.rzSliderHigh)
                  : (this.highValue = this.findStepIndex(this.scope.rzSliderHigh))
                : (this.highValue = this.scope.rzSliderHigh);
            },
            getStepValue: function (b) {
              var c = this.options.stepsArray[b];
              return a.isDate(c) ? c : a.isObject(c) ? c.value : c;
            },
            applyLowValue: function () {
              this.options.stepsArray
                ? this.options.bindIndexForStepsArray
                  ? (this.scope.rzSliderModel = this.lowValue)
                  : (this.scope.rzSliderModel = this.getStepValue(this.lowValue))
                : (this.scope.rzSliderModel = this.lowValue);
            },
            applyHighValue: function () {
              this.options.stepsArray
                ? this.options.bindIndexForStepsArray
                  ? (this.scope.rzSliderHigh = this.highValue)
                  : (this.scope.rzSliderHigh = this.getStepValue(this.highValue))
                : (this.scope.rzSliderHigh = this.highValue);
            },
            onLowHandleChange: function () {
              this.syncLowValue(),
                this.range && this.syncHighValue(),
                this.setMinAndMax(),
                this.updateLowHandle(this.valueToPosition(this.lowValue)),
                this.updateSelectionBar(),
                this.updateTicksScale(),
                this.updateAriaAttributes(),
                this.range && this.updateCmbLabel();
            },
            onHighHandleChange: function () {
              this.syncLowValue(),
                this.syncHighValue(),
                this.setMinAndMax(),
                this.updateHighHandle(this.valueToPosition(this.highValue)),
                this.updateSelectionBar(),
                this.updateTicksScale(),
                this.updateCmbLabel(),
                this.updateAriaAttributes();
            },
            applyOptions: function () {
              var b;
              (b = this.scope.rzSliderOptions ? this.scope.rzSliderOptions() : {}),
                (this.options = f.getOptions(b)),
                this.options.step <= 0 && (this.options.step = 1),
                (this.range =
                  void 0 !== this.scope.rzSliderModel && void 0 !== this.scope.rzSliderHigh),
                (this.options.draggableRange = this.range && this.options.draggableRange),
                (this.options.draggableRangeOnly = this.range && this.options.draggableRangeOnly),
                this.options.draggableRangeOnly && (this.options.draggableRange = !0),
                (this.options.showTicks =
                  this.options.showTicks ||
                  this.options.showTicksValues ||
                  !!this.options.ticksArray),
                (this.scope.showTicks = this.options.showTicks),
                (a.isNumber(this.options.showTicks) || this.options.ticksArray) &&
                  (this.intermediateTicks = !0),
                (this.options.showSelectionBar =
                  this.options.showSelectionBar ||
                  this.options.showSelectionBarEnd ||
                  null !== this.options.showSelectionBarFromValue),
                this.options.stepsArray
                  ? this.parseStepsArray()
                  : (this.options.translate
                      ? (this.customTrFn = this.options.translate)
                      : (this.customTrFn = function (a) {
                          return String(a);
                        }),
                    (this.getLegend = this.options.getLegend)),
                this.options.vertical &&
                  ((this.positionProperty = 'bottom'), (this.dimensionProperty = 'height')),
                this.options.customTemplateScope &&
                  (this.scope.custom = this.options.customTemplateScope);
            },
            parseStepsArray: function () {
              (this.options.floor = 0),
                (this.options.ceil = this.options.stepsArray.length - 1),
                (this.options.step = 1),
                this.options.translate
                  ? (this.customTrFn = this.options.translate)
                  : (this.customTrFn = function (a) {
                      return this.options.bindIndexForStepsArray ? this.getStepValue(a) : a;
                    }),
                (this.getLegend = function (b) {
                  var c = this.options.stepsArray[b];
                  return a.isObject(c) ? c.legend : null;
                });
            },
            resetSlider: function () {
              this.manageElementsStyle(),
                this.addAccessibility(),
                this.setMinAndMax(),
                this.updateCeilLab(),
                this.updateFloorLab(),
                this.unbindEvents(),
                this.manageEventsBindings(),
                this.setDisabledState(),
                this.calcViewDimensions(),
                this.refocusPointerIfNeeded();
            },
            refocusPointerIfNeeded: function () {
              this.currentFocusElement &&
                (this.onPointerFocus(
                  this.currentFocusElement.pointer,
                  this.currentFocusElement.ref,
                ),
                this.focusElement(this.currentFocusElement.pointer));
            },
            initElemHandles: function () {
              a.forEach(
                this.sliderElem.children(),
                function (b, c) {
                  var d = a.element(b);
                  switch (c) {
                    case 0:
                      this.leftOutSelBar = d;
                      break;
                    case 1:
                      this.rightOutSelBar = d;
                      break;
                    case 2:
                      this.fullBar = d;
                      break;
                    case 3:
                      this.selBar = d;
                      break;
                    case 4:
                      this.minH = d;
                      break;
                    case 5:
                      this.maxH = d;
                      break;
                    case 6:
                      this.flrLab = d;
                      break;
                    case 7:
                      this.ceilLab = d;
                      break;
                    case 8:
                      this.minLab = d;
                      break;
                    case 9:
                      this.maxLab = d;
                      break;
                    case 10:
                      this.cmbLab = d;
                      break;
                    case 11:
                      this.ticks = d;
                  }
                },
                this,
              ),
                (this.selBar.rzsp = 0),
                (this.minH.rzsp = 0),
                (this.maxH.rzsp = 0),
                (this.flrLab.rzsp = 0),
                (this.ceilLab.rzsp = 0),
                (this.minLab.rzsp = 0),
                (this.maxLab.rzsp = 0),
                (this.cmbLab.rzsp = 0);
            },
            manageElementsStyle: function () {
              this.range ? this.maxH.css('display', '') : this.maxH.css('display', 'none'),
                this.alwaysHide(
                  this.flrLab,
                  this.options.showTicksValues || this.options.hideLimitLabels,
                ),
                this.alwaysHide(
                  this.ceilLab,
                  this.options.showTicksValues || this.options.hideLimitLabels,
                );
              var a = this.options.showTicksValues && !this.intermediateTicks;
              this.alwaysHide(this.minLab, a || this.options.hidePointerLabels),
                this.alwaysHide(this.maxLab, a || !this.range || this.options.hidePointerLabels),
                this.alwaysHide(this.cmbLab, a || !this.range || this.options.hidePointerLabels),
                this.alwaysHide(this.selBar, !this.range && !this.options.showSelectionBar),
                this.alwaysHide(
                  this.leftOutSelBar,
                  !this.range || !this.options.showOuterSelectionBars,
                ),
                this.alwaysHide(
                  this.rightOutSelBar,
                  !this.range || !this.options.showOuterSelectionBars,
                ),
                this.range &&
                  this.options.showOuterSelectionBars &&
                  this.fullBar.addClass('rz-transparent'),
                this.options.vertical && this.sliderElem.addClass('rz-vertical'),
                this.options.draggableRange
                  ? this.selBar.addClass('rz-draggable')
                  : this.selBar.removeClass('rz-draggable'),
                this.intermediateTicks &&
                  this.options.showTicksValues &&
                  this.ticks.addClass('rz-ticks-values-under');
            },
            alwaysHide: function (a, b) {
              (a.rzAlwaysHide = b), b ? this.hideEl(a) : this.showEl(a);
            },
            manageEventsBindings: function () {
              this.options.disabled || this.options.readOnly
                ? this.unbindEvents()
                : this.bindEvents();
            },
            setDisabledState: function () {
              this.options.disabled
                ? this.sliderElem.attr('disabled', 'disabled')
                : this.sliderElem.attr('disabled', null);
            },
            resetLabelsValue: function () {
              (this.minLab.rzsv = void 0), (this.maxLab.rzsv = void 0);
            },
            initHandles: function () {
              this.updateLowHandle(this.valueToPosition(this.lowValue)),
                this.range && this.updateHighHandle(this.valueToPosition(this.highValue)),
                this.updateSelectionBar(),
                this.range && this.updateCmbLabel(),
                this.updateTicksScale();
            },
            translateFn: function (a, b, c, d) {
              d = void 0 === d ? !0 : d;
              var e = '',
                f = !1,
                g = b.hasClass('no-label-injection');
              d
                ? (this.options.stepsArray &&
                    !this.options.bindIndexForStepsArray &&
                    (a = this.getStepValue(a)),
                  (e = String(this.customTrFn(a, this.options.id, c))))
                : (e = String(a)),
                (void 0 === b.rzsv ||
                  b.rzsv.length !== e.length ||
                  (b.rzsv.length > 0 && 0 === b.rzsd)) &&
                  ((f = !0), (b.rzsv = e)),
                g || b.html(e),
                (this.scope[c + 'Label'] = e),
                f && this.getDimension(b);
            },
            setMinAndMax: function () {
              if (
                ((this.step = +this.options.step),
                (this.precision = +this.options.precision),
                (this.minValue = this.options.floor),
                this.options.logScale && 0 === this.minValue)
              )
                throw Error("Can't use floor=0 with logarithmic scale");
              this.options.enforceStep &&
                ((this.lowValue = this.roundStep(this.lowValue)),
                this.range && (this.highValue = this.roundStep(this.highValue))),
                null != this.options.ceil
                  ? (this.maxValue = this.options.ceil)
                  : (this.maxValue = this.options.ceil =
                      this.range ? this.highValue : this.lowValue),
                this.options.enforceRange &&
                  ((this.lowValue = this.sanitizeValue(this.lowValue)),
                  this.range && (this.highValue = this.sanitizeValue(this.highValue))),
                this.applyLowValue(),
                this.range && this.applyHighValue(),
                (this.valueRange = this.maxValue - this.minValue);
            },
            addAccessibility: function () {
              this.minH.attr('role', 'slider'),
                this.updateAriaAttributes(),
                !this.options.keyboardSupport || this.options.readOnly || this.options.disabled
                  ? this.minH.attr('tabindex', '')
                  : this.minH.attr('tabindex', '0'),
                this.options.vertical && this.minH.attr('aria-orientation', 'vertical'),
                this.options.ariaLabel
                  ? this.minH.attr('aria-label', this.options.ariaLabel)
                  : this.options.ariaLabelledBy &&
                    this.minH.attr('aria-labelledby', this.options.ariaLabelledBy),
                this.range &&
                  (this.maxH.attr('role', 'slider'),
                  !this.options.keyboardSupport || this.options.readOnly || this.options.disabled
                    ? this.maxH.attr('tabindex', '')
                    : this.maxH.attr('tabindex', '0'),
                  this.options.vertical && this.maxH.attr('aria-orientation', 'vertical'),
                  this.options.ariaLabelHigh
                    ? this.maxH.attr('aria-label', this.options.ariaLabelHigh)
                    : this.options.ariaLabelledByHigh &&
                      this.maxH.attr('aria-labelledby', this.options.ariaLabelledByHigh));
            },
            updateAriaAttributes: function () {
              this.minH.attr({
                'aria-valuenow': this.scope.rzSliderModel,
                'aria-valuetext': this.customTrFn(
                  this.scope.rzSliderModel,
                  this.options.id,
                  'model',
                ),
                'aria-valuemin': this.minValue,
                'aria-valuemax': this.maxValue,
              }),
                this.range &&
                  this.maxH.attr({
                    'aria-valuenow': this.scope.rzSliderHigh,
                    'aria-valuetext': this.customTrFn(
                      this.scope.rzSliderHigh,
                      this.options.id,
                      'high',
                    ),
                    'aria-valuemin': this.minValue,
                    'aria-valuemax': this.maxValue,
                  });
            },
            calcViewDimensions: function () {
              var a = this.getDimension(this.minH);
              if (
                ((this.handleHalfDim = a / 2),
                (this.barDimension = this.getDimension(this.fullBar)),
                (this.maxPos = this.barDimension - a),
                this.getDimension(this.sliderElem),
                (this.sliderElem.rzsp =
                  this.sliderElem[0].getBoundingClientRect()[this.positionProperty]),
                this.initHasRun)
              ) {
                this.updateFloorLab(), this.updateCeilLab(), this.initHandles();
                var c = this;
                b(function () {
                  c.updateTicksScale();
                });
              }
            },
            updateTicksScale: function () {
              if (this.options.showTicks) {
                var a = this.options.ticksArray || this.getTicksArray(),
                  b = this.options.vertical ? 'translateY' : 'translateX',
                  c = this;
                this.options.rightToLeft && a.reverse(),
                  (this.scope.ticks = a.map(function (a) {
                    var d = c.valueToPosition(a);
                    c.options.vertical && (d = c.maxPos - d);
                    var e = b + '(' + Math.round(d) + 'px)',
                      f = {
                        selected: c.isTickSelected(a),
                        style: {
                          '-webkit-transform': e,
                          '-moz-transform': e,
                          '-o-transform': e,
                          '-ms-transform': e,
                          transform: e,
                        },
                      };
                    if (
                      (f.selected &&
                        c.options.getSelectionBarColor &&
                        (f.style['background-color'] = c.getSelectionBarColor()),
                      !f.selected &&
                        c.options.getTickColor &&
                        (f.style['background-color'] = c.getTickColor(a)),
                      c.options.ticksTooltip &&
                        ((f.tooltip = c.options.ticksTooltip(a)),
                        (f.tooltipPlacement = c.options.vertical ? 'right' : 'top')),
                      (c.options.showTicksValues === !0 || a % c.options.showTicksValues === 0) &&
                        ((f.value = c.getDisplayValue(a, 'tick-value')),
                        c.options.ticksValuesTooltip &&
                          ((f.valueTooltip = c.options.ticksValuesTooltip(a)),
                          (f.valueTooltipPlacement = c.options.vertical ? 'right' : 'top'))),
                      c.getLegend)
                    ) {
                      var g = c.getLegend(a, c.options.id);
                      g && (f.legend = g);
                    }
                    return f;
                  }));
              }
            },
            getTicksArray: function () {
              var a = this.step,
                b = [];
              this.intermediateTicks && (a = this.options.showTicks);
              for (var c = this.minValue; c <= this.maxValue; c += a) b.push(c);
              return b;
            },
            isTickSelected: function (a) {
              if (!this.range)
                if (null !== this.options.showSelectionBarFromValue) {
                  var b = this.options.showSelectionBarFromValue;
                  if (this.lowValue > b && a >= b && a <= this.lowValue) return !0;
                  if (this.lowValue < b && b >= a && a >= this.lowValue) return !0;
                } else if (this.options.showSelectionBarEnd) {
                  if (a >= this.lowValue) return !0;
                } else if (this.options.showSelectionBar && a <= this.lowValue) return !0;
              return this.range && a >= this.lowValue && a <= this.highValue ? !0 : !1;
            },
            updateFloorLab: function () {
              this.translateFn(this.minValue, this.flrLab, 'floor'), this.getDimension(this.flrLab);
              var a = this.options.rightToLeft ? this.barDimension - this.flrLab.rzsd : 0;
              this.setPosition(this.flrLab, a);
            },
            updateCeilLab: function () {
              this.translateFn(this.maxValue, this.ceilLab, 'ceil'),
                this.getDimension(this.ceilLab);
              var a = this.options.rightToLeft ? 0 : this.barDimension - this.ceilLab.rzsd;
              this.setPosition(this.ceilLab, a);
            },
            updateHandles: function (a, b) {
              'lowValue' === a ? this.updateLowHandle(b) : this.updateHighHandle(b),
                this.updateSelectionBar(),
                this.updateTicksScale(),
                this.range && this.updateCmbLabel();
            },
            getHandleLabelPos: function (a, b) {
              var c = this[a].rzsd,
                d = b - c / 2 + this.handleHalfDim,
                e = this.barDimension - c;
              return this.options.boundPointerLabels
                ? (this.options.rightToLeft && 'minLab' === a) ||
                  (!this.options.rightToLeft && 'maxLab' === a)
                  ? Math.min(d, e)
                  : Math.min(Math.max(d, 0), e)
                : d;
            },
            updateLowHandle: function (a) {
              if (
                (this.setPosition(this.minH, a),
                this.translateFn(this.lowValue, this.minLab, 'model'),
                this.setPosition(this.minLab, this.getHandleLabelPos('minLab', a)),
                this.options.getPointerColor)
              ) {
                var b = this.getPointerColor('min');
                this.scope.minPointerStyle = { backgroundColor: b };
              }
              this.options.autoHideLimitLabels && this.shFloorCeil();
            },
            updateHighHandle: function (a) {
              if (
                (this.setPosition(this.maxH, a),
                this.translateFn(this.highValue, this.maxLab, 'high'),
                this.setPosition(this.maxLab, this.getHandleLabelPos('maxLab', a)),
                this.options.getPointerColor)
              ) {
                var b = this.getPointerColor('max');
                this.scope.maxPointerStyle = { backgroundColor: b };
              }
              this.options.autoHideLimitLabels && this.shFloorCeil();
            },
            shFloorCeil: function () {
              if (!this.options.hidePointerLabels) {
                var a = !1,
                  b = !1,
                  c = this.isLabelBelowFloorLab(this.minLab),
                  d = this.isLabelAboveCeilLab(this.minLab),
                  e = this.isLabelAboveCeilLab(this.maxLab),
                  f = this.isLabelBelowFloorLab(this.cmbLab),
                  g = this.isLabelAboveCeilLab(this.cmbLab);
                if (
                  (c ? ((a = !0), this.hideEl(this.flrLab)) : ((a = !1), this.showEl(this.flrLab)),
                  d ? ((b = !0), this.hideEl(this.ceilLab)) : ((b = !1), this.showEl(this.ceilLab)),
                  this.range)
                ) {
                  var h = this.cmbLabelShown ? g : e,
                    i = this.cmbLabelShown ? f : c;
                  h ? this.hideEl(this.ceilLab) : b || this.showEl(this.ceilLab),
                    i ? this.hideEl(this.flrLab) : a || this.showEl(this.flrLab);
                }
              }
            },
            isLabelBelowFloorLab: function (a) {
              var b = this.options.rightToLeft,
                c = a.rzsp,
                d = a.rzsd,
                e = this.flrLab.rzsp,
                f = this.flrLab.rzsd;
              return b ? c + d >= e - 2 : e + f + 2 >= c;
            },
            isLabelAboveCeilLab: function (a) {
              var b = this.options.rightToLeft,
                c = a.rzsp,
                d = a.rzsd,
                e = this.ceilLab.rzsp,
                f = this.ceilLab.rzsd;
              return b ? e + f + 2 >= c : c + d >= e - 2;
            },
            updateSelectionBar: function () {
              var a = 0,
                b = 0,
                c = this.options.rightToLeft
                  ? !this.options.showSelectionBarEnd
                  : this.options.showSelectionBarEnd,
                d = this.options.rightToLeft
                  ? this.maxH.rzsp + this.handleHalfDim
                  : this.minH.rzsp + this.handleHalfDim;
              if (this.range) (b = Math.abs(this.maxH.rzsp - this.minH.rzsp)), (a = d);
              else if (null !== this.options.showSelectionBarFromValue) {
                var e = this.options.showSelectionBarFromValue,
                  f = this.valueToPosition(e),
                  g = this.options.rightToLeft ? this.lowValue <= e : this.lowValue > e;
                g
                  ? ((b = this.minH.rzsp - f), (a = f + this.handleHalfDim))
                  : ((b = f - this.minH.rzsp), (a = this.minH.rzsp + this.handleHalfDim));
              } else
                c
                  ? ((b = Math.abs(this.maxPos - this.minH.rzsp) + this.handleHalfDim),
                    (a = this.minH.rzsp + this.handleHalfDim))
                  : ((b = this.minH.rzsp + this.handleHalfDim), (a = 0));
              if (
                (this.setDimension(this.selBar, b),
                this.setPosition(this.selBar, a),
                this.range &&
                  this.options.showOuterSelectionBars &&
                  (this.options.rightToLeft
                    ? (this.setDimension(this.rightOutSelBar, a),
                      this.setPosition(this.rightOutSelBar, 0),
                      this.setDimension(
                        this.leftOutSelBar,
                        this.getDimension(this.fullBar) - (a + b),
                      ),
                      this.setPosition(this.leftOutSelBar, a + b))
                    : (this.setDimension(this.leftOutSelBar, a),
                      this.setPosition(this.leftOutSelBar, 0),
                      this.setDimension(
                        this.rightOutSelBar,
                        this.getDimension(this.fullBar) - (a + b),
                      ),
                      this.setPosition(this.rightOutSelBar, a + b))),
                this.options.getSelectionBarColor)
              ) {
                var h = this.getSelectionBarColor();
                this.scope.barStyle = { backgroundColor: h };
              } else if (this.options.selectionBarGradient) {
                var i =
                    null !== this.options.showSelectionBarFromValue
                      ? this.valueToPosition(this.options.showSelectionBarFromValue)
                      : 0,
                  j = (i - a > 0) ^ c,
                  k = this.options.vertical ? (j ? 'bottom' : 'top') : j ? 'left' : 'right';
                (this.scope.barStyle = {
                  backgroundImage:
                    'linear-gradient(to ' +
                    k +
                    ', ' +
                    this.options.selectionBarGradient.from +
                    ' 0%,' +
                    this.options.selectionBarGradient.to +
                    ' 100%)',
                }),
                  this.options.vertical
                    ? ((this.scope.barStyle.backgroundPosition =
                        'center ' + (i + b + a + (j ? -this.handleHalfDim : 0)) + 'px'),
                      (this.scope.barStyle.backgroundSize =
                        '100% ' + (this.barDimension - this.handleHalfDim) + 'px'))
                    : ((this.scope.barStyle.backgroundPosition =
                        i - a + (j ? this.handleHalfDim : 0) + 'px center'),
                      (this.scope.barStyle.backgroundSize =
                        this.barDimension - this.handleHalfDim + 'px 100%'));
              }
            },
            getSelectionBarColor: function () {
              return this.range
                ? this.options.getSelectionBarColor(
                    this.scope.rzSliderModel,
                    this.scope.rzSliderHigh,
                  )
                : this.options.getSelectionBarColor(this.scope.rzSliderModel);
            },
            getPointerColor: function (a) {
              return 'max' === a
                ? this.options.getPointerColor(this.scope.rzSliderHigh, a)
                : this.options.getPointerColor(this.scope.rzSliderModel, a);
            },
            getTickColor: function (a) {
              return this.options.getTickColor(a);
            },
            updateCmbLabel: function () {
              var a = null;
              if (
                (a = this.options.rightToLeft
                  ? this.minLab.rzsp - this.minLab.rzsd - 10 <= this.maxLab.rzsp
                  : this.minLab.rzsp + this.minLab.rzsd + 10 >= this.maxLab.rzsp)
              ) {
                var b = this.getDisplayValue(this.lowValue, 'model'),
                  c = this.getDisplayValue(this.highValue, 'high'),
                  d = '';
                (d =
                  this.options.mergeRangeLabelsIfSame && b === c
                    ? b
                    : this.options.rightToLeft
                    ? c + ' - ' + b
                    : b + ' - ' + c),
                  this.translateFn(d, this.cmbLab, 'cmb', !1);
                var e = this.options.boundPointerLabels
                  ? Math.min(
                      Math.max(this.selBar.rzsp + this.selBar.rzsd / 2 - this.cmbLab.rzsd / 2, 0),
                      this.barDimension - this.cmbLab.rzsd,
                    )
                  : this.selBar.rzsp + this.selBar.rzsd / 2 - this.cmbLab.rzsd / 2;
                this.setPosition(this.cmbLab, e),
                  (this.cmbLabelShown = !0),
                  this.hideEl(this.minLab),
                  this.hideEl(this.maxLab),
                  this.showEl(this.cmbLab);
              } else
                (this.cmbLabelShown = !1),
                  this.updateHighHandle(this.valueToPosition(this.highValue)),
                  this.updateLowHandle(this.valueToPosition(this.lowValue)),
                  this.showEl(this.maxLab),
                  this.showEl(this.minLab),
                  this.hideEl(this.cmbLab);
              this.options.autoHideLimitLabels && this.shFloorCeil();
            },
            getDisplayValue: function (a, b) {
              return (
                this.options.stepsArray &&
                  !this.options.bindIndexForStepsArray &&
                  (a = this.getStepValue(a)),
                this.customTrFn(a, this.options.id, b)
              );
            },
            roundStep: function (a, b) {
              var c = b ? b : this.step,
                d = parseFloat((a - this.minValue) / c).toPrecision(12);
              d = Math.round(+d) * c;
              var e = (this.minValue + d).toFixed(this.precision);
              return +e;
            },
            hideEl: function (a) {
              return a.css({ visibility: 'hidden' });
            },
            showEl: function (a) {
              return a.rzAlwaysHide ? a : a.css({ visibility: 'visible' });
            },
            setPosition: function (a, b) {
              a.rzsp = b;
              var c = {};
              return (c[this.positionProperty] = Math.round(b) + 'px'), a.css(c), b;
            },
            getDimension: function (a) {
              var b = a[0].getBoundingClientRect();
              return (
                this.options.vertical
                  ? (a.rzsd = (b.bottom - b.top) * this.options.scale)
                  : (a.rzsd = (b.right - b.left) * this.options.scale),
                a.rzsd
              );
            },
            setDimension: function (a, b) {
              a.rzsd = b;
              var c = {};
              return (c[this.dimensionProperty] = Math.round(b) + 'px'), a.css(c), b;
            },
            sanitizeValue: function (a) {
              return Math.min(Math.max(a, this.minValue), this.maxValue);
            },
            valueToPosition: function (a) {
              var b = this.linearValueToPosition;
              this.options.customValueToPosition
                ? (b = this.options.customValueToPosition)
                : this.options.logScale && (b = this.logValueToPosition),
                (a = this.sanitizeValue(a));
              var c = b(a, this.minValue, this.maxValue) || 0;
              return this.options.rightToLeft && (c = 1 - c), c * this.maxPos;
            },
            linearValueToPosition: function (a, b, c) {
              var d = c - b;
              return (a - b) / d;
            },
            logValueToPosition: function (a, b, c) {
              (a = Math.log(a)), (b = Math.log(b)), (c = Math.log(c));
              var d = c - b;
              return (a - b) / d;
            },
            positionToValue: function (a) {
              var b = a / this.maxPos;
              this.options.rightToLeft && (b = 1 - b);
              var c = this.linearPositionToValue;
              return (
                this.options.customPositionToValue
                  ? (c = this.options.customPositionToValue)
                  : this.options.logScale && (c = this.logPositionToValue),
                c(b, this.minValue, this.maxValue) || 0
              );
            },
            linearPositionToValue: function (a, b, c) {
              return a * (c - b) + b;
            },
            logPositionToValue: function (a, b, c) {
              (b = Math.log(b)), (c = Math.log(c));
              var d = a * (c - b) + b;
              return Math.exp(d);
            },
            getEventAttr: function (a, b) {
              return void 0 === a.originalEvent ? a[b] : a.originalEvent[b];
            },
            getEventXY: function (a, b) {
              var c = this.options.vertical ? 'clientY' : 'clientX';
              if (void 0 !== a[c]) return a[c];
              var d = this.getEventAttr(a, 'touches');
              if (void 0 !== b)
                for (var e = 0; e < d.length; e++) if (d[e].identifier === b) return d[e][c];
              return d[0][c];
            },
            getEventPosition: function (a, b) {
              var c = this.sliderElem.rzsp,
                d = 0;
              return (
                (d = this.options.vertical
                  ? -this.getEventXY(a, b) + c
                  : this.getEventXY(a, b) - c),
                d * this.options.scale - this.handleHalfDim
              );
            },
            getEventNames: function (a) {
              var b = { moveEvent: '', endEvent: '' };
              return (
                this.getEventAttr(a, 'touches')
                  ? ((b.moveEvent = 'touchmove'), (b.endEvent = 'touchend'))
                  : ((b.moveEvent = 'mousemove'), (b.endEvent = 'mouseup')),
                b
              );
            },
            getNearestHandle: function (a) {
              if (!this.range) return this.minH;
              var b = this.getEventPosition(a),
                c = Math.abs(b - this.minH.rzsp),
                d = Math.abs(b - this.maxH.rzsp);
              return d > c
                ? this.minH
                : c > d
                ? this.maxH
                : this.options.rightToLeft
                ? b > this.minH.rzsp
                  ? this.minH
                  : this.maxH
                : b < this.minH.rzsp
                ? this.minH
                : this.maxH;
            },
            focusElement: function (a) {
              var b = 0;
              a[b].focus();
            },
            bindEvents: function () {
              var b, c, d;
              this.options.draggableRange
                ? ((b = 'rzSliderDrag'), (c = this.onDragStart), (d = this.onDragMove))
                : ((b = 'lowValue'), (c = this.onStart), (d = this.onMove)),
                this.options.onlyBindHandles ||
                  (this.selBar.on('mousedown', a.bind(this, c, null, b)),
                  this.selBar.on('mousedown', a.bind(this, d, this.selBar))),
                this.options.draggableRangeOnly
                  ? (this.minH.on('mousedown', a.bind(this, c, null, b)),
                    this.maxH.on('mousedown', a.bind(this, c, null, b)))
                  : (this.minH.on('mousedown', a.bind(this, this.onStart, this.minH, 'lowValue')),
                    this.range &&
                      this.maxH.on('mousedown', a.bind(this, this.onStart, this.maxH, 'highValue')),
                    this.options.onlyBindHandles ||
                      (this.fullBar.on('mousedown', a.bind(this, this.onStart, null, null)),
                      this.fullBar.on('mousedown', a.bind(this, this.onMove, this.fullBar)),
                      this.ticks.on('mousedown', a.bind(this, this.onStart, null, null)),
                      this.ticks.on('mousedown', a.bind(this, this.onTickClick, this.ticks)))),
                this.options.onlyBindHandles ||
                  (this.selBar.on('touchstart', a.bind(this, c, null, b)),
                  this.selBar.on('touchstart', a.bind(this, d, this.selBar))),
                this.options.draggableRangeOnly
                  ? (this.minH.on('touchstart', a.bind(this, c, null, b)),
                    this.maxH.on('touchstart', a.bind(this, c, null, b)))
                  : (this.minH.on('touchstart', a.bind(this, this.onStart, this.minH, 'lowValue')),
                    this.range &&
                      this.maxH.on(
                        'touchstart',
                        a.bind(this, this.onStart, this.maxH, 'highValue'),
                      ),
                    this.options.onlyBindHandles ||
                      (this.fullBar.on('touchstart', a.bind(this, this.onStart, null, null)),
                      this.fullBar.on('touchstart', a.bind(this, this.onMove, this.fullBar)),
                      this.ticks.on('touchstart', a.bind(this, this.onStart, null, null)),
                      this.ticks.on('touchstart', a.bind(this, this.onTickClick, this.ticks)))),
                this.options.keyboardSupport &&
                  (this.minH.on('focus', a.bind(this, this.onPointerFocus, this.minH, 'lowValue')),
                  this.range &&
                    this.maxH.on(
                      'focus',
                      a.bind(this, this.onPointerFocus, this.maxH, 'highValue'),
                    ));
            },
            unbindEvents: function () {
              this.minH.off(),
                this.maxH.off(),
                this.fullBar.off(),
                this.selBar.off(),
                this.ticks.off();
            },
            onStart: function (b, d, e) {
              var f,
                g,
                h = this.getEventNames(e);
              e.stopPropagation(),
                e.preventDefault(),
                this.calcViewDimensions(),
                b
                  ? (this.tracking = d)
                  : ((b = this.getNearestHandle(e)),
                    (this.tracking = b === this.minH ? 'lowValue' : 'highValue')),
                b.addClass('rz-active'),
                this.options.keyboardSupport && this.focusElement(b),
                (f = a.bind(this, this.dragging.active ? this.onDragMove : this.onMove, b)),
                (g = a.bind(this, this.onEnd, f)),
                c.on(h.moveEvent, f),
                c.on(h.endEvent, g),
                (this.endHandlerToBeRemovedOnEnd = g),
                this.callOnStart();
              var i = this.getEventAttr(e, 'changedTouches');
              i && (this.touchId || ((this.isDragging = !0), (this.touchId = i[0].identifier)));
            },
            onMove: function (b, c, d) {
              var e,
                f = this.getEventAttr(c, 'changedTouches');
              if (f)
                for (var g = 0; g < f.length; g++)
                  if (f[g].identifier === this.touchId) {
                    e = f[g];
                    break;
                  }
              if (!f || e) {
                var h,
                  i = this.getEventPosition(c, e ? e.identifier : void 0),
                  j = this.options.rightToLeft ? this.minValue : this.maxValue,
                  k = this.options.rightToLeft ? this.maxValue : this.minValue;
                0 >= i
                  ? (h = k)
                  : i >= this.maxPos
                  ? (h = j)
                  : ((h = this.positionToValue(i)),
                    (h =
                      d && a.isNumber(this.options.showTicks)
                        ? this.roundStep(h, this.options.showTicks)
                        : this.roundStep(h))),
                  this.positionTrackingHandle(h);
              }
            },
            onEnd: function (a, b) {
              var d = this.getEventAttr(b, 'changedTouches');
              if (!d || d[0].identifier === this.touchId) {
                (this.isDragging = !1),
                  (this.touchId = null),
                  this.options.keyboardSupport ||
                    (this.minH.removeClass('rz-active'),
                    this.maxH.removeClass('rz-active'),
                    (this.tracking = '')),
                  (this.dragging.active = !1);
                var e = this.getEventNames(b);
                c.off(e.moveEvent, a),
                  c.off(e.endEvent, this.endHandlerToBeRemovedOnEnd),
                  (this.endHandlerToBeRemovedOnEnd = null),
                  this.callOnEnd();
              }
            },
            onTickClick: function (a, b) {
              this.onMove(a, b, !0);
            },
            onPointerFocus: function (b, c) {
              (this.tracking = c),
                b.one('blur', a.bind(this, this.onPointerBlur, b)),
                b.on('keydown', a.bind(this, this.onKeyboardEvent)),
                b.on('keyup', a.bind(this, this.onKeyUp)),
                (this.firstKeyDown = !0),
                b.addClass('rz-active'),
                (this.currentFocusElement = { pointer: b, ref: c });
            },
            onKeyUp: function () {
              (this.firstKeyDown = !0), this.callOnEnd();
            },
            onPointerBlur: function (a) {
              a.off('keydown'),
                a.off('keyup'),
                a.removeClass('rz-active'),
                this.isDragging || ((this.tracking = ''), (this.currentFocusElement = null));
            },
            getKeyActions: function (a) {
              var b = a + this.step,
                c = a - this.step,
                d = a + this.valueRange / 10,
                e = a - this.valueRange / 10;
              this.options.reversedControls &&
                ((b = a - this.step),
                (c = a + this.step),
                (d = a - this.valueRange / 10),
                (e = a + this.valueRange / 10));
              var f = {
                UP: b,
                DOWN: c,
                LEFT: c,
                RIGHT: b,
                PAGEUP: d,
                PAGEDOWN: e,
                HOME: this.options.reversedControls ? this.maxValue : this.minValue,
                END: this.options.reversedControls ? this.minValue : this.maxValue,
              };
              return (
                this.options.rightToLeft &&
                  ((f.LEFT = b),
                  (f.RIGHT = c),
                  this.options.vertical && ((f.UP = c), (f.DOWN = b))),
                f
              );
            },
            onKeyboardEvent: function (a) {
              var c = this[this.tracking],
                d = a.keyCode || a.which,
                e = {
                  38: 'UP',
                  40: 'DOWN',
                  37: 'LEFT',
                  39: 'RIGHT',
                  33: 'PAGEUP',
                  34: 'PAGEDOWN',
                  36: 'HOME',
                  35: 'END',
                },
                f = this.getKeyActions(c),
                g = e[d],
                h = f[g];
              if (null != h && '' !== this.tracking) {
                a.preventDefault(),
                  this.firstKeyDown && ((this.firstKeyDown = !1), this.callOnStart());
                var i = this;
                b(function () {
                  var a = i.roundStep(i.sanitizeValue(h));
                  if (i.options.draggableRangeOnly) {
                    var b,
                      c,
                      d = i.highValue - i.lowValue;
                    'lowValue' === i.tracking
                      ? ((b = a), (c = a + d), c > i.maxValue && ((c = i.maxValue), (b = c - d)))
                      : ((c = a), (b = a - d), b < i.minValue && ((b = i.minValue), (c = b + d))),
                      i.positionTrackingBar(b, c);
                  } else i.positionTrackingHandle(a);
                });
              }
            },
            onDragStart: function (a, b, c) {
              var d = this.getEventPosition(c);
              (this.dragging = {
                active: !0,
                value: this.positionToValue(d),
                difference: this.highValue - this.lowValue,
                lowLimit: this.options.rightToLeft ? this.minH.rzsp - d : d - this.minH.rzsp,
                highLimit: this.options.rightToLeft ? d - this.maxH.rzsp : this.maxH.rzsp - d,
              }),
                this.onStart(a, b, c);
            },
            getValue: function (a, b, c, d) {
              var e = this.options.rightToLeft,
                f = null;
              return (
                (f =
                  'min' === a
                    ? c
                      ? d
                        ? e
                          ? this.minValue
                          : this.maxValue - this.dragging.difference
                        : e
                        ? this.maxValue - this.dragging.difference
                        : this.minValue
                      : e
                      ? this.positionToValue(b + this.dragging.lowLimit)
                      : this.positionToValue(b - this.dragging.lowLimit)
                    : c
                    ? d
                      ? e
                        ? this.minValue + this.dragging.difference
                        : this.maxValue
                      : e
                      ? this.maxValue
                      : this.minValue + this.dragging.difference
                    : e
                    ? this.positionToValue(b + this.dragging.lowLimit) + this.dragging.difference
                    : this.positionToValue(b - this.dragging.lowLimit) + this.dragging.difference),
                this.roundStep(f)
              );
            },
            onDragMove: function (a, b) {
              var c,
                d,
                e,
                f,
                g,
                h,
                i,
                j,
                k = this.getEventPosition(b);
              if (
                (this.options.rightToLeft
                  ? ((e = this.dragging.lowLimit),
                    (f = this.dragging.highLimit),
                    (i = this.maxH),
                    (j = this.minH))
                  : ((e = this.dragging.highLimit),
                    (f = this.dragging.lowLimit),
                    (i = this.minH),
                    (j = this.maxH)),
                (g = f >= k),
                (h = k >= this.maxPos - e),
                g)
              ) {
                if (0 === i.rzsp) return;
                (c = this.getValue('min', k, !0, !1)), (d = this.getValue('max', k, !0, !1));
              } else if (h) {
                if (j.rzsp === this.maxPos) return;
                (d = this.getValue('max', k, !0, !0)), (c = this.getValue('min', k, !0, !0));
              } else (c = this.getValue('min', k, !1)), (d = this.getValue('max', k, !1));
              this.positionTrackingBar(c, d);
            },
            positionTrackingBar: function (a, b) {
              null != this.options.minLimit &&
                a < this.options.minLimit &&
                ((a = this.options.minLimit), (b = a + this.dragging.difference)),
                null != this.options.maxLimit &&
                  b > this.options.maxLimit &&
                  ((b = this.options.maxLimit), (a = b - this.dragging.difference)),
                (this.lowValue = a),
                (this.highValue = b),
                this.applyLowValue(),
                this.range && this.applyHighValue(),
                this.applyModel(!0),
                this.updateHandles('lowValue', this.valueToPosition(a)),
                this.updateHandles('highValue', this.valueToPosition(b));
            },
            positionTrackingHandle: function (a) {
              var b = !1;
              (a = this.applyMinMaxLimit(a)),
                this.range &&
                  (this.options.pushRange
                    ? ((a = this.applyPushRange(a)), (b = !0))
                    : (this.options.noSwitching &&
                        ('lowValue' === this.tracking && a > this.highValue
                          ? (a = this.applyMinMaxRange(this.highValue))
                          : 'highValue' === this.tracking &&
                            a < this.lowValue &&
                            (a = this.applyMinMaxRange(this.lowValue))),
                      (a = this.applyMinMaxRange(a)),
                      'lowValue' === this.tracking && a > this.highValue
                        ? ((this.lowValue = this.highValue),
                          this.applyLowValue(),
                          this.applyModel(),
                          this.updateHandles(this.tracking, this.maxH.rzsp),
                          this.updateAriaAttributes(),
                          (this.tracking = 'highValue'),
                          this.minH.removeClass('rz-active'),
                          this.maxH.addClass('rz-active'),
                          this.options.keyboardSupport && this.focusElement(this.maxH),
                          (b = !0))
                        : 'highValue' === this.tracking &&
                          a < this.lowValue &&
                          ((this.highValue = this.lowValue),
                          this.applyHighValue(),
                          this.applyModel(),
                          this.updateHandles(this.tracking, this.minH.rzsp),
                          this.updateAriaAttributes(),
                          (this.tracking = 'lowValue'),
                          this.maxH.removeClass('rz-active'),
                          this.minH.addClass('rz-active'),
                          this.options.keyboardSupport && this.focusElement(this.minH),
                          (b = !0)))),
                this[this.tracking] !== a &&
                  ((this[this.tracking] = a),
                  'lowValue' === this.tracking ? this.applyLowValue() : this.applyHighValue(),
                  this.applyModel(),
                  this.updateHandles(this.tracking, this.valueToPosition(a)),
                  this.updateAriaAttributes(),
                  (b = !0)),
                b && this.applyModel(!0);
            },
            applyMinMaxLimit: function (a) {
              return null != this.options.minLimit && a < this.options.minLimit
                ? this.options.minLimit
                : null != this.options.maxLimit && a > this.options.maxLimit
                ? this.options.maxLimit
                : a;
            },
            applyMinMaxRange: function (a) {
              var b = 'lowValue' === this.tracking ? this.highValue : this.lowValue,
                c = Math.abs(a - b);
              return null != this.options.minRange && c < this.options.minRange
                ? 'lowValue' === this.tracking
                  ? this.highValue - this.options.minRange
                  : this.lowValue + this.options.minRange
                : null != this.options.maxRange && c > this.options.maxRange
                ? 'lowValue' === this.tracking
                  ? this.highValue - this.options.maxRange
                  : this.lowValue + this.options.maxRange
                : a;
            },
            applyPushRange: function (a) {
              var b = 'lowValue' === this.tracking ? this.highValue - a : a - this.lowValue,
                c = null !== this.options.minRange ? this.options.minRange : this.options.step,
                d = this.options.maxRange;
              return (
                c > b
                  ? ('lowValue' === this.tracking
                      ? ((this.highValue = Math.min(a + c, this.maxValue)),
                        (a = this.highValue - c),
                        this.applyHighValue(),
                        this.updateHandles('highValue', this.valueToPosition(this.highValue)))
                      : ((this.lowValue = Math.max(a - c, this.minValue)),
                        (a = this.lowValue + c),
                        this.applyLowValue(),
                        this.updateHandles('lowValue', this.valueToPosition(this.lowValue))),
                    this.updateAriaAttributes())
                  : null !== d &&
                    b > d &&
                    ('lowValue' === this.tracking
                      ? ((this.highValue = a + d),
                        this.applyHighValue(),
                        this.updateHandles('highValue', this.valueToPosition(this.highValue)))
                      : ((this.lowValue = a - d),
                        this.applyLowValue(),
                        this.updateHandles('lowValue', this.valueToPosition(this.lowValue))),
                    this.updateAriaAttributes()),
                a
              );
            },
            applyModel: function (a) {
              (this.internalChange = !0),
                this.scope.$apply(),
                a && this.callOnChange(),
                (this.internalChange = !1);
            },
            callOnStart: function () {
              if (this.options.onStart) {
                var a = this,
                  b = 'lowValue' === this.tracking ? 'min' : 'max';
                this.scope.$evalAsync(function () {
                  a.options.onStart(a.options.id, a.scope.rzSliderModel, a.scope.rzSliderHigh, b);
                });
              }
            },
            callOnChange: function () {
              if (this.options.onChange) {
                var a = this,
                  b = 'lowValue' === this.tracking ? 'min' : 'max';
                this.scope.$evalAsync(function () {
                  a.options.onChange(a.options.id, a.scope.rzSliderModel, a.scope.rzSliderHigh, b);
                });
              }
            },
            callOnEnd: function () {
              if (this.options.onEnd) {
                var a = this,
                  b = 'lowValue' === this.tracking ? 'min' : 'max';
                this.scope.$evalAsync(function () {
                  a.options.onEnd(a.options.id, a.scope.rzSliderModel, a.scope.rzSliderHigh, b);
                });
              }
              this.scope.$emit('slideEnded');
            },
          }),
          h
        );
      },
    ])
    .directive('rzslider', [
      'RzSlider',
      function (a) {
        return {
          restrict: 'AE',
          replace: !0,
          scope: {
            rzSliderModel: '=?',
            rzSliderHigh: '=?',
            rzSliderOptions: '&?',
            rzSliderTplUrl: '@',
          },
          templateUrl: function (a, b) {
            return b.rzSliderTplUrl || 'rzSliderTpl.html';
          },
          link: function (b, c) {
            b.slider = new a(b, c);
          },
        };
      },
    ]);
  return (
    b.run([
      '$templateCache',
      function (a) {
        a.put(
          'rzSliderTpl.html',
          '<div class=rzslider><span class="rz-bar-wrapper rz-left-out-selection"><span class=rz-bar></span></span> <span class="rz-bar-wrapper rz-right-out-selection"><span class=rz-bar></span></span> <span class=rz-bar-wrapper><span class=rz-bar></span></span> <span class=rz-bar-wrapper><span class="rz-bar rz-selection" ng-style=barStyle></span></span> <span class="rz-pointer rz-pointer-min" ng-style=minPointerStyle></span> <span class="rz-pointer rz-pointer-max" ng-style=maxPointerStyle></span> <span class="rz-bubble rz-limit rz-floor"></span> <span class="rz-bubble rz-limit rz-ceil"></span> <span class="rz-bubble rz-model-value"></span> <span class="rz-bubble rz-model-high"></span> <span class=rz-bubble></span><ul ng-show=showTicks class=rz-ticks><li ng-repeat="t in ticks track by $index" class=rz-tick ng-class="{\'rz-selected\': t.selected}" ng-style=t.style ng-attr-uib-tooltip="{{ t.tooltip }}" ng-attr-tooltip-placement={{t.tooltipPlacement}} ng-attr-tooltip-append-to-body="{{ t.tooltip ? true : undefined}}"><span ng-if="t.value != null" class=rz-tick-value ng-attr-uib-tooltip="{{ t.valueTooltip }}" ng-attr-tooltip-placement={{t.valueTooltipPlacement}}>{{ t.value }}</span> <span ng-if="t.legend != null" class=rz-tick-legend>{{ t.legend }}</span></li></ul></div>',
        );
      },
    ]),
    b.name
  );
});
